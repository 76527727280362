export default {
    /**
     * Get access token
     * @param state
     * @returns {any}
     */
    token: state => {
        return 'Bearer ' + state.user.signInUserSession.idToken
    },
    /**
     * Check if user is auth
     * @param state (this state)
     * @returns {boolean}
     */
    isAuth: state => {
        if (state.user) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * Get menu items for specific route
     * @param state
     * @param path
     * @returns {{"/video/download": [{title: string, url: string},{title: string, url: string}], "/video/upload": [{title: string, url: string},{title: string, url: string}]}}
     */
    getMenuLayout: (state,path) => {
        return state.menu
    },
    getUserInfo: (state,path) =>{
        return state.user
    },
    getAllProjects: (state) => {
        return state.allProjects
    },
    getAllDevicesForProject: (state) => {
        return state.selectedProjectsDevices
    }

}
