/**
 * Menu for every page on layout
 */
export default {
  '/video/live': [
    {
      title: 'Live',
      url: '/video/live',
    },
  ],
}
