<template src="./template.html"></template>

<script>
export default {
  name: 'edit-profile',
  props: {
    isEditProfileActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null
    }
  },
  methods: {
    /**
     * Close modal
     */
    closeModal() {
      this.$emit('editProfileStatus', false)
    },
    /**
     * Change password amplify
     */
    changePassword() {
      this.$auth.currentAuthenticatedUser()
          .then(user => {
            return this.$auth.changePassword(user, this.oldPassword, this.newPassword);
          })
          .then((data) => {
            //Seccess notification
            this.$buefy.notification.open({
              message: 'Password successfully changed',
              position: 'is-bottom-right',
              duration: 5000,
              type: 'is-success'
            })
            this.closeModal()
          }).catch((error) => {
        //Faild notification
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
      });
    }
  }
}
</script>
<style lang="scss" scoped src="./style.scss"></style>
