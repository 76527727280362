<template src="./template.html"></template>

<script>
/* eslint-disable */
 import editProfile from '@/modules/core/edit-profile/edit-profile'
export default {
  name: 'LayoutDefault',
  components:{
     editProfile
  },
  props: {
    layout: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isLoading:false,
      isEditProfileActive:false,
      layoutMenu:[]
    };
  },
  /**
   * Mounted
   */
  mounted:function(){

  },
  methods: {
    /**
     * Logout Cognito
     */
    logout() {
      this.isLoading = true;
      this.$auth.signOut().then(() => {
        this.$router.push('/')
        this.isLoading = false;
      })
    }
  },
  watch: {
    /**
     * Get new many data when route is changed
     * @param to
     * @param from
     */
    $route(to, from) {
      let store =  this.$store.getters.getMenuLayout
      this.layoutMenu = store[to.path];
    }
  }
};
</script>
