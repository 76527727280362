export default {
    /**
     * Save user data
     * @param state (this state)
     * @param item
     */
    saveUserData(state, item) {
        state.user = item;
    },
    /**
     * Update user data
     * @param state (this state)
     * @param updatedItem
     * @returns {*}
     */
    updateUserData(state, updatedItem) {
        state.user = updatedItem;
        return updatedItem;
    },
    /**
     * Remove user data
     * @param state (this state)
     * @param item
     */
    removeUserData(state, item) {
        state.user = null
    },
    /**
     * Update all projects after request
     * @param state (this state)
     * @param items
     */
    updateAllProjects(state,items) {
        state.allProjects = items
    },
    /**
     * Update selected device after request
     * @param state (this state)
     * @param item
     */
    updateSelectedProjectsDevices(state,items) {
        state.selectedProjectsDevices = items
    },
};
