<template>
  <div>
    <LayoutDefault :layout="layout" :layoutMenu="layoutMenu">
      <router-view @updateLayout="updateLayout"/>
    </LayoutDefault>
  </div>
</template>

<script>
import LayoutDefault from './layouts/default/default';

export default {
  name: 'Home-Page',
  components: {
    LayoutDefault
  },
  data() {
    return {
      layout: false,
      layoutMenu: []
    }
  },
  mounted: function () {
    this.$auth.currentAuthenticatedUser().then((user) => {
      this.$store.commit('saveUserData', user)
    });
  },
  methods: {
    /**
     * update layout (Disable (false) / Enable (true)
     * @param data
     */
    updateLayout(data) {
      this.layout = data;
    }
  }
};
</script>
