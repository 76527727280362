import Vue from "vue";
import Vuex from "vuex";

import mutations from "@/store/mutations";
import getters from "@/store/getters";
import actions from "@/store/actions";
import menu from "@/router/menu"
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //Menu for layout
        menu:menu,
        //User data from cognito
        user: null,
        allProjects: [],
        selectedProjectsDevices: []
    },
    mutations: mutations,
    getters: getters,
    actions: actions
});
