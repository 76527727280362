//Vue & Vuex
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store/default_store";
//Map import
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
//Amplify (Auth)
import {Amplify, Auth} from 'aws-amplify';
//Axios
import Axios from 'axios'
//Buefy import
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
//SCSS Custom theme
import './main.scss'
import vmodal from 'vue-js-modal'

/**
 * Global HTTP
 * @type {AxiosStatic}
 */
Vue.prototype.$http = Axios;
/**
 * Global Auth
 * @type {AuthClass}
 */
Vue.prototype.$auth = Auth;

/**
 * Access to ENV file
 * @type {{readonly VUE_APP_API_URL?: string, readonly VUE_APP_TITLE?: string, readonly VUE_APP_ACCESS_TOKEN?: string, readonly NODE_ENV?: string, readonly VUE_APP_SIGN_UP_RECAPTCHA_KEY?: string, readonly VUE_APP_IDENTITY_POOL_ID?: string, readonly VUE_APP_REGION?: string, readonly VUE_APP_USER_POOL_WEB_CLIENT_ID?: string, readonly VUE_APP_USER_POOL_ID?: string, readonly PATH?: string, readonly __CFBundleIdentifier?: string, readonly SHELL?: string, readonly CONDA_DEFAULT_ENV?: string, readonly CONDA_EXE?: string, readonly OLDPWD?: string, readonly CONDA_PYTHON_EXE?: string, readonly USER?: string, readonly TMPDIR?: string, readonly COMMAND_MODE?: string, readonly SSH_AUTH_SOCK?: string, readonly CONDA_PREFIX?: string, readonly _CE_CONDA?: string, readonly XPC_FLAGS?: string, readonly _CE_M?: string, readonly __CF_USER_TEXT_ENCODING?: string, readonly CONDA_PROMPT_MODIFIER?: string, readonly LOGNAME?: string, readonly LC_CTYPE?: string, readonly XPC_SERVICE_NAME?: string, readonly HOME?: string, readonly CONDA_SHLVL?: string, [p: string]: string}}
 */
Vue.prototype.$env = process.env;
/**
 * Amplify configuration
 */
Amplify.configure({
    Auth: {
        identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID, // example: 'us-east-2:c85f3c18-05fd-4bb5-8fd1-e77e7627a99e'
        region: process.env.VUE_APP_REGION, // example: 'us-east-2'
        userPoolId: process.env.VUE_APP_USER_POOL_ID, // example: 'us-east-2_teEUQbkUh'
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID // example: '3k09ptd8kn8qk2hpk07qopr86'
    }
});

/**
 * Interceptors
 * If user is loged in and API url start with RTT API url automatic add
 * JWT token
 */
Vue.prototype.$http.interceptors.request.use(
    config => {
        try {
            if(config.url.includes(process.env.VUE_APP_API_URL)){
                const isApiUrl = config.url.startsWith(process.env.VUE_APP_API_URL);
                return Vue.prototype.$auth.currentAuthenticatedUser().then((user) => {
                    if (isApiUrl) {
                        config.headers['Authorization'] = `Bearer ` + user.signInUserSession.idToken.jwtToken;
                        return config;
                    }
                });
            }else{
                return config;
            }

        } catch (e) {
            return config;
        }
    },
    error => {
        return Promise.reject(error);
    }
);


Vue.config.productionTip = true

/**
 * Vue enable plugins
 */
Vue.use(VueMapbox, {mapboxgl: Mapbox});
Vue.use(Buefy)
Vue.use(vmodal)
/**
 * Vue start
 */
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
