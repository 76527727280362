export default {
    /**
     * Get all projects with specification
     * @param state
     */
    getAllProjects(context) {
        var data = new FormData();
        data.append('user_email', context.getters.getUserInfo.attributes.email);
        let config = {
            method: 'post',
            url: this._vm.$env.VUE_APP_CCTV_API_URL + '/api/get-all-projects',
            data : data
        };
        this._vm.$http(config).then(function (response) {
            context.commit('updateAllProjects',response.data)
        }).catch(function (error) {
            console.log(error);
        });
    },

    /**
     * Get all devices for project
     * @param device_id
     */
    getAllDevicesForProject(context, device_id) {
        var data = new FormData();
        data.append('user_email', context.getters.getUserInfo.attributes.email);
        let config = {
            method: 'post',
            url: this._vm.$env.VUE_APP_CCTV_API_URL + '/api/get-all-devices',
            data : data
        };

        this._vm.$http(config).then(function (response) {
            context.commit('updateSelectedProjectsDevices',response.data)
        }).catch(function (error) {
            console.log(error);
        });
    }
}
