import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

Vue.use(VueRouter)

const routes = [
  /**
   * Core
   */
  {
    path: '/',
    name: 'login',
    component: () => import('../modules/core/login/login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../modules/core/register/register.vue'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../modules/core/forgot/forgot.vue'),
  },

  /**
   * Video Segment
   */
  {
    path: '/video/live',
    name: 'video-live',
    component: () => import('../modules/video/video-live/video-live.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})
/**
 * Auth check if user is loged in
 */
router.beforeEach(async (to, from, next) => {
  //Disable check just for these routes
  if (to.name == 'login' || to.name == 'register' || to.name == 'forgot') {
    //NOT Authorized
    next()
  } else {
    //Authorized
    await Auth.currentAuthenticatedUser()
      .then(() => {
        next()
      })
      .catch((error) => {
        //NOT Authorized
        next({ name: 'login' })
      })
  }
})
export default router
